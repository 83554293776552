import { useEventListener } from '@vueuse/core';
import { isHtmlElement } from '../../../js/helpers/type-guards/isHtmlElement';
import { KeyEnum } from '../../../js/enums/enums';
import { isGivenHtmlType } from '../../../js/helpers/type-guards/isGivenHtmlType';
import { ModalTypesEnum } from './enums';

const addClassesToDOM = () => {
    const style = document.createElement('style');

    style.innerHTML = `
      .no-scroll {
        overflow: hidden;
      }

      .display-none {
        display: none !important;
      }
    `;

    document.head.appendChild(style);
};

const toggleScrollability = () => {
    document.body.classList.toggle('no-scroll');
};

const setupModal = (modalTrigger: HTMLDivElement) => {
    const modal = document.getElementById('modal');
    const modalContent: HTMLDivElement = modal.querySelector('#modal-content');
    const closeButton: HTMLElement = modal.querySelector('.modal-component__close');

    const ANIMATION_DURATION = 300; // Linked with '$animation-duration' in '/components/modals/modal.scss'
    const MODAL_LOADER_ID = 'modal-loader';

    let removeKeydownListener: () => void;
    let removeBackdropListener: () => void;
    let removeCloseButtonListener: () => void;

    const cleanUpEvents = () => {
        removeKeydownListener?.();
        removeBackdropListener?.();
        removeCloseButtonListener?.();
    };

    const cleanupContent = () => {
        const { type } = modalTrigger.dataset;

        if (type === ModalTypesEnum.VIDEO) {
            const modalLoader = modalContent.querySelector(`#${MODAL_LOADER_ID}`);

            setTimeout(() => {
                modalContent.removeChild(modalContent.firstChild);

                if (modalLoader && modalContent.contains(modalLoader)) {
                    modalContent.removeChild(modalLoader);
                }
            }, ANIMATION_DURATION);
        }
    };

    const closeModal = () => {
        modal.classList.remove('show');

        setTimeout(() => {
            modal.style.display = 'none';
        }, ANIMATION_DURATION);

        toggleScrollability();
        cleanUpEvents();
        cleanupContent();
    };

    const closeModalOnBackdropClick = (eventTarget: EventTarget) => {
        if (!isHtmlElement(eventTarget)) {
            return;
        }

        const MODAL_ID = 'modal';
        const targetId = eventTarget.id;

        if (targetId === MODAL_ID) {
            closeModal();
        }
    };

    const handleEscapeKeydown = (pressedKey: KeyboardEvent['key']) => {
        if (pressedKey === KeyEnum.ESCAPE) {
            closeModal();
        }
    };

    const applyModalStyles = () => {
        const {
            width,
            height,
            background,
            borderRadius,
            closeIconColor,
            mobileFullscreen,
        } = modalTrigger.dataset;

        modalContent.style.width = width;
        modalContent.style.height = height;
        modalContent.style.background = background;
        modalContent.style.borderRadius = borderRadius;

        closeButton.style.color = closeIconColor;

        if (mobileFullscreen) {
            modalContent.style.overflow = 'scroll';
            modalContent.classList.add('modal-component__content--fullscreen');
        }

        modal.style.display = 'flex';

        setTimeout(() => { // Needed for animation
            modal.classList.add('show');
        });
    };

    const setModalContent = () => {
        const { type, videoSrc } = modalTrigger.dataset;

        if (!type) {
            return;
        }

        if (type === ModalTypesEnum.VIDEO) {
            if (!videoSrc) {
                return;
            }

            const createIframe = (videoSrc: string) => {
                const iframe = document.createElement('iframe');

                iframe.classList.add('video-modal__iframe', 'display-none');
                iframe.src = videoSrc;
                iframe.title = 'YouTube Video Player';
                iframe.allowFullscreen = true;

                return iframe;
            };

            const createLoader = () => {
                const loaderContainer = document.createElement('div');
                const loader = document.createElement('div');

                loaderContainer.id = MODAL_LOADER_ID;
                loaderContainer.classList.add('modal-component__loader-container');
                loader.classList.add('modal-component__loader');
                loaderContainer.appendChild(loader);

                return loaderContainer;
            };

            const removeLoader = (loaderContainer: HTMLDivElement, iframe: HTMLIFrameElement) => {
                modalContent.removeChild(loaderContainer);
                iframe.classList.remove('display-none');
            };

            const loaderContainer = createLoader();
            modalContent.insertBefore(loaderContainer, modalContent.firstChild);

            const iframe = createIframe(videoSrc);

            useEventListener(
                iframe,
                'load',
                () => removeLoader(loaderContainer, iframe),
            );
            modalContent.insertBefore(iframe, modalContent.firstChild);
        }
    };

    const openModal = () => {
        if (!closeButton) {
            return;
        }

        toggleScrollability();
        setModalContent();
        applyModalStyles();

        removeKeydownListener = useEventListener(
            closeButton,
            'click',
            closeModal,
            { once: true },
        );
        removeBackdropListener = useEventListener(
            modal,
            'click',
            (event) => closeModalOnBackdropClick(event.target),
        );
        removeCloseButtonListener = useEventListener(
            window,
            'keydown',
            (event) => handleEscapeKeydown(event.key),
        );
    };

    openModal();
};

const addClickListener = () => {
    useEventListener(document, 'click', (event) => {
        const clickedElement = event.target;

        if (!isGivenHtmlType(clickedElement, HTMLElement)) {
            return;
        }

        const modalTrigger: HTMLDivElement = clickedElement.closest('.modal-component__trigger');

        if (modalTrigger) {
            setupModal(modalTrigger);
        }
    });
};

const handleCodeIdentifier = () => {
    /*
        This function prevents duplicate event listeners by ensuring the file only runs once.
        The issue arises due to the use of `remoteModalOpen`,
        which can cause the script to execute multiple times, leading to event duplication.
    */
    if (document.body.getAttribute('data-code-identifier')) {
        return true;
    }

    document.body.setAttribute('data-code-identifier', 'true');

    return false;
};

useEventListener(document, 'DOMContentLoaded', () => {
    if (handleCodeIdentifier()) {
        return;
    }
    addClassesToDOM();
    addClickListener();
});

export const remoteModalOpen = (fakeTriggerElement: HTMLDivElement) => {
    setupModal(fakeTriggerElement);
};
